import preval from 'babel-plugin-preval/macro'

function getUiSpecTree(productId) {
	const modelCode = productId.split('.')[0]
	const productCode = productId.split('.')[2]

	// form:
	// modelCode:	name:		groups:
	// AW:					WAVE:		material, size, shape, thickness:
	const model = uiSpecModels.find((model) => model.modelCode === modelCode)
	console.assert(model !== undefined, 'cannot find the requested model in uiSpecModels:', modelCode, uiSpecModels)
	// console.log('ui spec', structuredClone(model))

	// this is just to show the friendly preset name; no related logic
	const product = uiSpecProducts.find((product) => product.modelCode === modelCode && product.productCode === productCode)
	console.assert(product !== undefined, 'cannot find the requested product in uiSpecProducts. modelCode:', modelCode, ', productCode:', productCode, uiSpecProducts)
	model['productName'] = product.name

	// form:
	// modelCode:	groupCode:	name:			params:
	// AW:					material:		Material:	TabColorId,MaterialIdExternal,MaterialThicknessMM
	const groupCodes = model.groups.split(',').map((code) => code.trim())
	const groups = groupCodes.map((groupCode) => uiSpecGroups.find((group) => group.groupCode === groupCode && (group.modelCode === modelCode || group.modelCode === '*')))
	console.assert(!groups.includes(undefined), 'cannot find a group', groupCodes, groups)
	model.groups = groups // replace the group id's with group objects

	// form:
	// modelCode:	rhinoName:						icon:		name:						abbreviation:	min:		max:		keyvaluepairs:
	// *:						MaterialThicknessMM:	none:		ply thickness:	W:						-:			-:			18mm=1.8, 9mm=0.9:
	// AW:					WidthMax:							none:		width:					W:						-100:		100:		-:
	groups.forEach((group) => {
		const paramCodes = group.params.split(',').map((code) => code.trim())
		const params = paramCodes.map((paramCode) => uiSpecParams.find((param) => param.rhinoName === paramCode && (param.modelCode === modelCode || param.modelCode === '*')))
		console.assert(!params.includes(undefined), 'cannot find a param', paramCodes, params)
		group.params = params // replace the param id's with param objects
		// console.log('params ', params)
	})
	// console.log('ui tree', model)
	return model
}

//========================================================
// The following is done at compile time so we can read
// data from the server that isn't available in the browser.
//========================================================

// read ui spec data from the server at compile time. TODO make this a dev dependency
const uiRawSpecModels = preval`
const fs = require('fs')
module.exports = fs.readFileSync('./src/data/models.txt', 'utf8')
`
const uiRawSpecGroups = preval`
const fs = require('fs')
module.exports = fs.readFileSync('./src/data/groups.txt', 'utf8')
`
const uiRawSpecParams = preval`
const fs = require('fs')
module.exports = fs.readFileSync('./src/data/params.txt', 'utf8')
`
const uiRawSpecProducts = preval`
const fs = require('fs')
module.exports = fs.readFileSync('./src/data/products.txt', 'utf8')
`
// console.log('=======================starting processing of compile-time data')
// convert to json format
const uiSpecModels = createUiSpec(uiRawSpecModels)
const uiSpecGroups = createUiSpec(uiRawSpecGroups)
const uiSpecParams = createUiSpec(uiRawSpecParams)
const uiSpecProducts = createUiSpec(uiRawSpecProducts)
// console.log('=======================finished processing of compile-time data')

function createUiSpec(uiRawSpec) {
	const allRawLines = uiRawSpec.split('\n').filter((line) => line.trim() !== '')
	// console.log(allRawLines)
	// the column header names are in the first line
	let colNames = splitLine(allRawLines[0])
	// the remaining lines are the specs
	let specLinesCells = allRawLines.slice(1).map((line) => splitLine(line))
	// remove the empty lines at the end if there any
	specLinesCells = specLinesCells.filter((element) => {
		return Object.keys(element).length !== 0
	})
	// convert to json
	return specLinesCells.map((cells) => jsonify(colNames, cells))
}

// convert to json format by adding the column headers to each value
function jsonify(names, values) {
	var items = {}
	names.forEach((name, index) => (items[name] = index < values.length ? values[index] : '-')) // add empties if the values row has empty cells at the end
	// console.log(names, values, items)
	return items
}

// split the raw lines into individual elements (i.e., the cells)
function splitLine(uiRawSpecLine) {
	const elements = uiRawSpecLine.split(':')
	const result = []
	// trim out the formatting-only tabs
	elements.forEach((element) => {
		const str = element.trim()
		result.push(str)
	})
	if (result[result.length - 1] === '') {
		result.pop()
	} // if present, remove the empty cell at the end
	return result
}

function getRawSpecsModel() {
	return uiRawSpecModels
}

function getRawSpecsProduct() {
	return uiRawSpecProducts
}

function getRawSpecsParams() {
	return uiRawSpecParams
}

// model specs in object form; for all models (productId undefined), or a particular productId
function modelSpecs(productId) {
	const allModels = createUiSpec(getRawSpecsModel())

	if (productId === undefined) return allModels // EARLY RETURN

	const modelCode = productId.split('.')[0] // AW, AS, etc.
	return allModels.filter((m) => m.modelCode === modelCode)[0] // return the single spec object for productId
}

function modelPlacement(productId) {
	return modelSpecs(productId).placement
}

function isModelPlacementWall(productId) {
	return modelPlacement(productId) === 'wall'
}

function cameraProps(productId) {
	const mSpec = modelSpecs(productId)
	const camHtPct = mSpec.camHtPct
	const camAzDeg = mSpec.camAzDeg
	return { camHtPct, camAzDeg }
}

function price(productId, area, materialIdExternal) {
	const mSpec = modelSpecs(productId)
	const priceBase = +mSpec.priceBase
	const priceArea = +mSpec.priceArea
	let materialPremium = +1
	// material premiums are of the form: 11=1.3, 21=1.15, 22=1.15
	if (mSpec.priceMatPrem.includes(materialIdExternal + '=')) {
		// if we're here we know the specs include the desired material id
		const matPremsKVs = mSpec.priceMatPrem.split(',')
		const matPremKV = matPremsKVs.filter((pairStr) => pairStr.split('=')[0] === materialIdExternal)[0]
		const matPrem = matPremKV.split('=')[1]
		materialPremium = +matPrem
	}
	const _price = (priceBase + priceArea * area) * materialPremium
	// console.log(_price, area, priceBase, priceArea, materialPremium)
	return _price
}

function paramSpecs(productId) {
	const allParams = createUiSpec(getRawSpecsParams())

	if (productId === undefined) return allParams // EARLY RETURN

	const modelCode = productId.split('.')[0] // AW, AS, etc.
	return allParams.filter((m) => (m.modelCode === modelCode) | (m.modelCode === '*')) // return the single spec object for productId
}

// Normalize all text to a particular case format.
// This works well for 'ALL UPPER', or 'all lower'. 'Capitalized' works well for English
// but not a well for other languages. So, if capitalized is desired, that must be done
// in the original source, such as params.txt.
function toProperCase(str) {
	// console.log(str)
	return str
	// return str.indexOf(' ') === -1
	// 	? str.charAt(0).toUpperCase() + str.slice(1).toLowerCase()
	// 	: str
	// 			.split(' ')
	// 			.map((word) => {
	// 				return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
	// 			})
	// 			.join(' ')
}

function truncateString(string, limit) {
	if (string.length > limit) {
		return string.substring(0, limit) + '..'
	} else {
		return string
	}
}

module.exports = {
	getUiSpecTree,
	getRawSpecsProduct,
	modelPlacement,
	isModelPlacementWall,
	cameraProps,
	paramSpecs,
	toProperCase,
	truncateString,
	price,
}
