import { GlobalStateContext } from '../App'

import { useEffect, useState, useContext } from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import BlockBanner from './BlockBanner'
import { getRawSpecsProduct, toProperCase } from './_uiHelpers'
import { configPresetUrl } from './_makeUrls'
import { colorBackground, setFixedPageSizeMode } from './Global'
import { localize } from './_localization'

export default function SelectorGallery() {
	// console.log('Selector..Block')
	const { globalState } = useContext(GlobalStateContext)

	const [gallery, setGallery] = useState()

	useEffect(() => {
		// console.log('Select..1')

		setFixedPageSizeMode(false)

		const seriesSpecs = [
			['Wave', 'AW', 'B01'],
			['Cascade', 'AC', 'B01'],
			['Tide', 'AT', 'B01'],
			['Stream', 'AS', 'N01'],
			['', 'UA', 'B01'],
		]

		// TODO: if this page survives, redo with object-form product specs instead of raw
		const allRawLines = getRawSpecsProduct()
			.split('\n')
			.filter((line) => line.trim() !== '')
		allRawLines.shift() // eliminate the header row

		// get the friendly product names and associate them with the 'base' productId for that subproduct
		const idNameTriples = []
		allRawLines.forEach((rawId) => {
			const idComponents = rawId.split(':')
			const series = idComponents[0].trim()
			if (series !== 'UA' && series !== 'CL' && series !== 'FC' && series !== 'AF') {
				// omit new series that are not gallery-ready
				const subproduct = idComponents[1]
				const seriesSpec = seriesSpecs.find((spec) => spec[1] === series)
				const defaultAccent = seriesSpec[2] // e.g., "BO1" for most, "N01" for shelves
				const baseId = `${series}.${defaultAccent}.${subproduct}`
				idNameTriples.push([baseId, toProperCase(localize(idComponents[2], globalState.language).trim()), idComponents[3]]) // baseID (e.g., AW), name (e.g., COFFEE TABLE), img source (e.g., COFFEE_CURVE_Mobile_B01_8a6cc9c52c)
			}
		})

		// console.log(allRawLines, seriesSpecs, idNameTriples)

		setGallery(
			<div>
				{seriesSpecs /* .filter(spec => spec[1] !== 'AT') */
					.map((seriesTriple) => (
						<GalleryTitleDiv key={seriesTriple[1]}>
							<GalleryTitleText>{toProperCase(seriesTriple[0])}</GalleryTitleText>
							<GalleryRowDiv>
								{idNameTriples
									.filter((idTriple) => idTriple[0].startsWith(seriesTriple[1]))
									.map((productTriple) => (
										<Link
											to={configPresetUrl(productTriple[0], {})}
											target='_blank'
											style={{ alignSelf: 'center', textDecoration: 'none' }}
											key={productTriple[0]}
										>
											<ProductDiv>
												<ProductImgDiv>
													<ProductImg
														src={`https://stratumassets.s3.eu-central-1.amazonaws.com/${productTriple[2]}.jpg`}
													/>
												</ProductImgDiv>
												<ProductText>{productTriple[1]}</ProductText>
											</ProductDiv>
										</Link>
									))}
							</GalleryRowDiv>
						</GalleryTitleDiv>
					))}
				<GalleryTitleDiv>
					<GalleryTitleText>Swell</GalleryTitleText>
					<ProductDiv>
						<GalleryRowDiv>
							<ProductText style={{ lineHeight: 'unset', textAlign: 'center', margin: '25px 0 0 33px' }}>
								{localize('Personalization|Personnalisation|Personalisierung', globalState.language)}
								<br />
								{localize('coming soon.|Bientôt disponible.|demnächst.', globalState.language)}
								<br />
								<br />
								{localize('Pre-order now|Pré commandez maintenant|Jetzt vorbestellen', globalState.language)}
								<br />
								{localize('from our shop.|de notre boutique.|aus unserem Shop.', globalState.language)}
							</ProductText>
						</GalleryRowDiv>
					</ProductDiv>
				</GalleryTitleDiv>
			</div>
		)
	}, [globalState])

	return (
		<OuterDiv>
			<BlockBanner alwaysFullWidth={true} />
			<ContentDiv>
				<HeadlineDiv>
					<p style={{ marginTop: 0 }}>
						{localize(
							'Select a standard product as the starting point for your custom creation|Sélectionnez un produit standard comme point de départ de votre création personnalisée|Wählen Sie ein Standardprodukt als Ausgangspunkt für Ihre individuelle Kreation',
							globalState.language
						)}
					</p>
				</HeadlineDiv>
				{gallery}
			</ContentDiv>
		</OuterDiv>
	)
}

const OuterDiv = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
`

const ContentDiv = styled.div`
	padding-top: 50px;
	padding-left: 150px;
	background-color: ${colorBackground};
`

const HeadlineDiv = styled.div`
	margin: 0 0 0 -50px;
	font-family: 'Sharp Sans No1 Bold';
	font-style: normal;
	// font-weight: 800;
	font-size: 28px;
	line-height: 32px;
	letter-spacing: 0.05em;
	color: black;
`

const GalleryTitleDiv = styled.div``

const GalleryTitleText = styled.p`
	margin: 0;
	font-family: 'Sharp Sans No1 Bold';
	font-style: normal;
	// font-weight: 800;
	font-size: 24px;
	line-height: 32px;
	letter-spacing: 0.05em;
	color: black;
`

const GalleryRowDiv = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-contents: start;
`

const ProductDiv = styled.div`
	display: flex;
	flex-direction: column;
	margin: 20px;
	margin-top: 5px;
	width: 180px;
	height: 150px;
	background-color: black;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`

const ProductImgDiv = styled.div`
	overflow: hidden;
`

const ProductImg = styled.img`
	max-width: 100%;
	margin-top: -10px;
`

const ProductText = styled.p`
	height: 30px;
	margin: 0;
	align-self: center;
	font-family: 'Sharp Sans No1 Bold';
	font-style: normal;
	// font-weight: 800;
	font-size: 14px;
	line-height: 32px;
	letter-spacing: 0.05em;
	color: white;
	background-color: black;
`
